import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import translationHelper from "../i18n/helper"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import SectionTitleSeperator from "../components/Misc/SectionTitleSeperator/SectionTitleSeperator"
import BoxedItem from "../components/Misc/BoxedItem/boxedItem"
import Button from "react-bootstrap/Button"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import parse from "html-react-parser"
import localeConfig from "../constants/locales"
const ContactSent = (props) => {
  const locale = props.pageContext.locale
  const localePrefix = localeConfig[locale].default ? "" : localeConfig[locale].urlPrefix
  return (
    <Layout locale={props.pageContext.locale} path={props.path}>
      <SEO title={translationHelper[locale]["contactFormSent"]} lang={props.pageContext.locale}
         />
      <Container>
        <Row>
          <Col md={12}>
            <EmptySpaceSeperator pixels={40}/>
            <SectionTitleSeperator hideTop={true} title={translationHelper[locale]["contactFormSent"]}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <BoxedItem className="">
              <h3 className="p-5 text-center styledParagraph">
              {
                parse(translationHelper[locale]["contactFormSentMessage"])
              }
              </h3>
            </BoxedItem>
            <EmptySpaceSeperator pixels={80}/>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <Button href={localePrefix + "/"} type="button" size="lg" className="customBtn">
              {
                translationHelper[locale]["backHome"]
              }
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>

  )
}

export default ContactSent
